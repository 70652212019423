import React from "react";
import styles from "./Sensei.module.scss";
import {
	Chatbot,
} from "../../components";


function Sensei(props) {

	return (
		<div className={styles.sensei}>
			<Chatbot fullScreen />
		</div>
	)
}

export default Sensei;
import axios from 'axios';
import { API_URL } from '../constants';

const downloadPDF = async (content, baseFilename) => {
  try {
    const response = await axios.post(`${API_URL}/download_pdf`, {
      content: content,
      filename: baseFilename,
    });
    
    // Extract PDF data and filename
    const { pdfData, filename } = response.data;

    // Decode Base64 data and create a Blob
    const byteCharacters = atob(pdfData);
    const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Trigger download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // Clean up
    link.remove();
    window.URL.revokeObjectURL(url);
    return;
  } catch (error) {
    console.error("Error downloading PDF:", error);
    return;
  }
};

export default downloadPDF;

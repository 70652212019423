import { ReactComponent as ChatPasteGoIcon } from "./assets/icons/chat_paste_go.svg"
import { ReactComponent as HistoryEduIcon } from "./assets/icons/history_edu.svg"
import { ReactComponent as QuestionMarkIcon } from "./assets/icons/question_mark.svg"
import { ReactComponent as QuickReplyIcon } from "./assets/icons/quickreply.svg"
import TuneIcon from '@mui/icons-material/Tune';
import ConstructionIcon from '@mui/icons-material/Construction';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

// export const API_URL = 'http://localhost:9000';
// export const SOCKET_URL = 'ws://localhost:9000';

// export const API_URL = 'https://api.applysamurai.com';
// export const SOCKET_URL = 'ws://api.applysamurai.com';

export const API_URL = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const MIN_OUTPUT_ROWS_EXPORT = 20;
export const OUTPUT_ROWS_EXPORT = 35;
export const MAX_OUTPUT_ROWS_EXPORT = 35;

export const MIN_OUTPUT_ROWS = 20;
export const OUTPUT_ROWS = 40;
export const MAX_OUTPUT_ROWS = 40;

export const UUID_PERMIT = false;

export const CHATBOT_ENDPOINT = "/chatbot";

export const RECOMMENDED = [
  "USER_RESUME",
  "USER_LINKEDIN_PROFILE",
];

export const BG1 = "#003366";
export const BG2 = "#4a90e2";

export const SERVICES = {
	coverLetter: {
		title: "Cover Letter", name: "CoverLetter", endpoint: "cover_letter", href: "/cover-letter", icon: <HistoryEduIcon />, download: true, description: "",
		steps: {
			USER_RESUME: {label: "Resume", icon: "upload", optional: true, recommended: true, title: "Please upload your resume", stepKey: "USER_RESUME", href: "/resume", helperText: ""},
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			JOB_POSITION_POSTING: {label: "Job Posting", icon: "text", optional: false, recommended: null, title: "Please copy and paste the job posting here.", stepKey: "JOB_POSITION_POSTING", href: "/job-posting", helperText: ""},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			CONNECTIONS: {label: "Connections", icon: "text", optional: true, recommended: false, title: "Do you have any connections with the recipient? If so, who or what, and to what extent?", stepKey: "CONNECTIONS", href: "/connections", helperText: "Feel free to be as detailed as you would like, and to include any information that you think might be relevant, such as mutual connections, shared experiences, or anything else that might help us personalize the message."},
			output: {label: "Generate Cover Letter", icon: "generate", optional: false, recommended: null, title: "Cover Letter", descriptiveText: false, stepKey: "output", href: "/output", helperText: ""},
		},
	},
	followUp: {
		title: "Follow Up Message", name: "FollowUp", endpoint: "follow_up", href: "/follow-up", icon: <QuickReplyIcon />, download: false, description: "",
		steps: {
			USER_RESUME: {label: "Resume", icon: "upload", optional: true, recommended: true, title: "Please upload your resume", stepKey: "USER_RESUME", href: "/resume", helperText: ""},
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			JOB_POSITION_POSTING: {label: "Job Posting", icon: "text", optional: true, recommended: true, title: "Please copy and paste the job posting here.", stepKey: "JOB_POSITION_POSTING", href: "/job-posting", helperText: ""},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			MESSAGE_RECIPIENT_INFORMATION: {label: "Recipient Information", icon: "text", optional: false, recommended: null, title: "Please tell us a little bit about who this message is for.", stepKey: "MESSAGE_RECIPIENT_INFORMATION", href: "/recipient-info", helperText: ""},
			CONNECTIONS: {label: "Connections", icon: "text", optional: true, recommended: false, title: "Do you have any connections with the recipient? If so, who or what, and to what extent?", stepKey: "CONNECTIONS", href: "/connections", helperText: "Feel free to be as detailed as you would like, and to include any information that you think might be relevant, such as mutual connections, shared experiences, or anything else that might help us personalize the message."},
			FURTHER_CONTEXT: {label: "Additional Details", icon: "text", optional: true, recommended: false, title: "Is there anything else that you think we should know before generating the message?", stepKey: "FURTHER_CONTEXT", href: "/additional-details", helperText: ""},
			output: {label: "Generate Follow Up Message", icon: "generate", optional: false, recommended: null, title: "Follow Up Message", descriptiveText: false, stepKey: "output", href: "/output", helperText: ""},
		},
	},
	reachOut: {
		title: "Reach Out Message", name: "ReachOut", endpoint: "reach_out", href: "/reach-out", icon: <ChatPasteGoIcon />, download: false, description: "",
		steps: {
			USER_RESUME: {label: "Resume", icon: "upload", optional: true, recommended: true, title: "Please upload your resume", stepKey: "USER_RESUME", href: "/resume", helperText: ""},
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			COMPANY_INFORMATION: {label: "Company Information", icon: "text", optional: true, recommended: false, title: "Please tell us a little bit about the company, if any, that you are reaching out in regards to.", stepKey: "COMPANY_INFORMATION", href: "/company-info", helperText: ""},
			MESSAGE_RECIPIENT_INFORMATION: {label: "Recipient Information", icon: "text", optional: false, recommended: null, title: "Please tell us a little bit about who this message is for.", stepKey: "MESSAGE_RECIPIENT_INFORMATION", href: "/recipient-info", helperText: ""},
			CONNECTIONS: {label: "Connections", icon: "text", optional: true, recommended: false, title: "Do you have any connections with the recipient? If so, who or what, and to what extent?", stepKey: "CONNECTIONS", href: "/connections", helperText: "Feel free to be as detailed as you would like, and to include any information that you think might be relevant, such as mutual connections, shared experiences, or anything else that might help us personalize the message."},
			FURTHER_CONTEXT: {label: "Additional Details", icon: "text", optional: true, recommended: false, title: "Is there anything else that you think we should know before generating the message?", stepKey: "FURTHER_CONTEXT", href: "/additional-details", helperText: ""},
			output: {label: "Generate Reach Out Message", icon: "generate", optional: false, recommended: null, title: "Reach Out Message", descriptiveText: false, stepKey: "output", href: "/output", helperText: ""},
		},
	},
	questions: {
		title: "Answer An Application Question", name: "Questions", endpoint: "questions", href: "/questions", icon: <QuestionMarkIcon />, download: false, description: "",
		steps: {
			USER_RESUME: {label: "Resume", icon: "upload", optional: true, recommended: true, title: "Please upload your resume", stepKey: "USER_RESUME", href: "/resume", helperText: ""},
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			JOB_POSITION_POSTING: {label: "Job Posting", icon: "text", optional: true, recommended: true, title: "Please copy and paste the job posting here.", stepKey: "JOB_POSITION_POSTING", href: "/job-posting", helperText: ""},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			QUESTIONS: {label: "Question", icon: "text", optional: false, recommended: null, title: "Please copy and paste the application question here.", stepKey: "QUESTIONS", href: "/question", helperText: ""},
			FURTHER_CONTEXT: {label: "Additional Details", icon: "text", optional: true, recommended: false, title: "Is there anything else that you think we should know before generating the answer?", stepKey: "FURTHER_CONTEXT", href: "/additional-details", helperText: ""},
			output: {label: "Generate Answer", icon: "generate", optional: false, recommended: null, title: "Answer", descriptiveText: false, stepKey: "output", href: "/output", helperText: ""},
		},
	},
	buildResume: {
		title: "Build A Resume From Scratch", name: "BuildResume", endpoint: "build_resume", href: "/build-resume", icon: <ConstructionIcon sx={{ fontSize: 40 }} />, download: true, description: "",
		steps: {
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			output: {label: "Generate New Resume", icon: "generate", optional: false, recommended: null, title: "New Resume", descriptiveText: true, stepKey: "output", href: "/output", helperText: ""},
		},
	},
	improveResume: {
		title: "Improve An Existing Resume", name: "ImproveResume", endpoint: "improve_resume", href: "/improve-resume", icon: <KeyboardDoubleArrowUpIcon sx={{ fontSize: 40 }} />, download: true, description: "",
		steps: {
			USER_RESUME: {label: "Resume", icon: "upload", optional: false, recommended: null, title: "Please upload your current resume", stepKey: "USER_RESUME", href: "/resume", helperText: ""},
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			output: {label: "Generate Improved Resume", icon: "generate", optional: true, recommended: false, title: "Improved Resume", descriptiveText: true, stepKey: "output", href: "/output", helperText: ""},
		},
	},
	tuneResume: {
		title: "Tune A Resume For A Specific Job", name: "TuneResume", endpoint: "tune_resume", href: "/tune-resume", icon: <TuneIcon sx={{ fontSize: 40 }} />, download: true, description: "",
		steps: {
			USER_RESUME: {label: "Resume", icon: "upload", optional: false, recommended: null, title: "Please upload your current resume", stepKey: "USER_RESUME", href: "/resume", helperText: ""},
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			JOB_POSITION_POSTING: {label: "Job Posting", icon: "text", optional: false, recommended: null, title: "Please copy and paste the job posting here.", stepKey: "JOB_POSITION_POSTING", href: "/job-posting", helperText: ""},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			output: {label: "Generate Tuned Resume", icon: "generate", optional: true, recommended: false, title: "Tuned Resume", descriptiveText: true, stepKey: "output", href: "/output", helperText: ""},
		},
	},
}
import React, { useState } from "react";
import './App.scss';
import {
  Pipeline,
} from "./pages";
import {
  SERVICES,
} from "./constants";
import {
  useUUID,
} from "./hooks";
import { MantineProvider } from '@mantine/core';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import storage from "./util/storage";
import {
  BrowserRouter,
} from "react-router-dom";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1200,
      xl: 1600,
    },
  },
});

const DEFAULT_OUTPUTS = Object.fromEntries(Object.keys(SERVICES).map(e => ([e, {}])));
const DEFAULT_ACTIVE_OUTPUT_INDEX = Object.fromEntries(Object.keys(SERVICES).map(e => ([e, 1])));
const DEFAULT_OUTPUT_LENGTH = Object.fromEntries(Object.keys(SERVICES).map(e => ([e, 0])));
const DEFAULT_COMPLETED_STEPS = Object.fromEntries(Object.keys(SERVICES).map(e => ([e, []])));

function App() {
  const userKey = useUUID();

  const USER_RESUME = storage.step({ key: "userResume", defaultValue: "" });
  const USER_LINKEDIN_PROFILE = storage.step({ key: "userLinkedInProfile", defaultValue: "" });
  const USER_INFORMATION = storage.step({ key: "userInformation", defaultValue: "" });
  const COMPANY_INFORMATION = storage.step({ key: "companyInformation", defaultValue: "" });
  const JOB_POSITION_POSTING = storage.step({ key: "jobPositionPosting", defaultValue: "" });
  const MESSAGE_RECIPIENT_INFORMATION = storage.step({ key: "messageRecipientInformation", defaultValue: "" });
  const QUESTIONS = storage.step({ key: "questions", defaultValue: "" });
  const CONNECTIONS = storage.step({ key: "connections", defaultValue: "" });
  const FURTHER_CONTEXT = storage.step({ key: "furtherContext", defaultValue: "" });

  const [activeStepIndex, _setActiveStepIndex] = useState(storage.state({ key: "activeStepIndex", defaultValue: -1 }));
  const [activeStep, _setActiveStep] = useState(storage.state({ key: "activeStep", defaultValue: {} }));
	const [steps, _setSteps] = useState(storage.state({ key: "steps", defaultValue: [] }));
	const [service, _setService] = useState(storage.state({ key: "service", defaultValue: null }));
	const [data, _setData] = useState(storage.state({ key: "data", defaultValue: {} }));
	const [outputs, _setOutputs] = useState(storage.state({ key: "outputs", defaultValue: DEFAULT_OUTPUTS }));
	const [outputLength, _setOutputLength] = useState(storage.state({ key: "outputLength", defaultValue: DEFAULT_OUTPUT_LENGTH }));
	const [activeOutput, _setActiveOutput] = useState(storage.state({ key: "activeOutput", defaultValue: "" }));
	const [activeOutputIndex, _setActiveOutputIndex] = useState(storage.state({ key: "activeOutputIndex", defaultValue: DEFAULT_ACTIVE_OUTPUT_INDEX }));
	const [userResume, _setUserResume] = useState(USER_RESUME);
	const [userLinkedInProfile, _setUserLinkedInProfile] = useState(USER_LINKEDIN_PROFILE);
	const [userInformation, _setUserInformation] = useState(USER_INFORMATION);
	const [companyInformation, _setCompanyInformation] = useState(COMPANY_INFORMATION);
	const [jobPositionPosting, _setJobPositionPosting] = useState(JOB_POSITION_POSTING);
	const [messageRecipientInformation, _setMessageRecipientInformation] = useState(MESSAGE_RECIPIENT_INFORMATION);
	const [questions, _setQuestions] = useState(QUESTIONS);
	const [connections, _setConnections] = useState(CONNECTIONS);
	const [furtherContext, _setFurtherContext] = useState(FURTHER_CONTEXT);
	const [loading, _setLoading] = useState(storage.state({ key: "loading", defaultValue: false }));
	const [company, _setCompany] = useState(storage.state({ key: "company", defaultValue: "" }));
	const [jobTitle, _setJobTitle] = useState(storage.state({ key: "jobTitle", defaultValue: "" }));
  const [userName, _setUserName] = useState(storage.state({ key: "userName", defaultValue: "" }));
  const [professionalTitle, _setProfessionalTitle] = useState(storage.state({ key: "professionalTitle", defaultValue: "" }));
	const [disableNext, _setDisableNext] = useState(storage.state({ key: "disableNext", defaultValue: false }));
  const [completedSteps, _setCompletedSteps] = useState(storage.state({ key: "completedSteps", defaultValue: DEFAULT_COMPLETED_STEPS }));
  const [processUploadCalls, _setProcessUploadCalls] = useState(storage.state({ key: "processUploadCalls", defaultValue: [] }));
  const [placeholdersUsed, _setPlaceholdersUsed] = useState(storage.state({ key: "placeholdersUsed", defaultValue: false }));
  const [parse, _setParse] = useState(storage.state({ key: "parse", defaultValue: {
      USER_RESUME: USER_RESUME !== "",
      USER_LINKEDIN_PROFILE: USER_LINKEDIN_PROFILE !== "",
      USER_INFORMATION: USER_INFORMATION !== "",
      COMPANY_INFORMATION: COMPANY_INFORMATION !== "",
      JOB_POSITION_POSTING: JOB_POSITION_POSTING !== "",
      MESSAGE_RECIPIENT_INFORMATION: MESSAGE_RECIPIENT_INFORMATION !== "",
      QUESTIONS: QUESTIONS !== "",
      CONNECTIONS: CONNECTIONS !== "",
      FURTHER_CONTEXT: FURTHER_CONTEXT !== "",
    }
  }));
  

  const setActiveStepIndex = (m) => { _setActiveStepIndex(m); storage.state({ key: "activeStepIndex", value: m }); }
  const setActiveStep = (m) => { _setActiveStep(m); storage.state({ key: "activeStep", value: m }); }
  const setSteps = (m) => { _setSteps(m); storage.state({ key: "steps", value: m }); }
  const setService = (m) => { _setService(m); storage.state({ key: "service", value: m }); }
  const setData = (m) => { _setData(m); storage.state({ key: "data", value: m }); }
  const setOutputs = (m) => { _setOutputs(m); storage.state({ key: "outputs", value: m }); }
  const setOutputLength = (m) => { _setOutputLength(m); storage.state({ key: "outputLength", value: m }); }
  const setActiveOutput = (m) => { _setActiveOutput(m); storage.state({ key: "activeOutput", value: m }); }
  const setActiveOutputIndex = (m) => { _setActiveOutputIndex(m); storage.state({ key: "activeOutputIndex", value: m }); }
  const setUserResume = (m) => { _setUserResume(m); storage.step({ key: "userResume", value: m }); }
  const setUserLinkedInProfile = (m) => { _setUserLinkedInProfile(m); storage.step({ key: "userLinkedInProfile", value: m }); }
  const setUserInformation = (m) => { _setUserInformation(m); storage.step({ key: "userInformation", value: m }); }
  const setCompanyInformation = (m) => { _setCompanyInformation(m); storage.step({ key: "companyInformation", value: m }); }
  const setJobPositionPosting = (m) => { _setJobPositionPosting(m); storage.step({ key: "jobPositionPosting", value: m }); }
  const setMessageRecipientInformation = (m) => { _setMessageRecipientInformation(m); storage.step({ key: "messageRecipientInformation", value: m }); }
  const setQuestions = (m) => { _setQuestions(m); storage.step({ key: "questions", value: m }); }
  const setConnections = (m) => { _setConnections(m); storage.step({ key: "connections", value: m }); }
  const setFurtherContext = (m) => { _setFurtherContext(m); storage.step({ key: "furtherContext", value: m }); }
  const setLoading = (m) => { _setLoading(m); storage.state({ key: "loading", value: m }); }
  const setCompany = (m) => { _setCompany(m); storage.state({ key: "company", value: m }); }
  const setJobTitle = (m) => { _setJobTitle(m); storage.state({ key: "jobTitle", value: m }); }
  const setUserName = (m) => { _setUserName(m); storage.state({ key: "userName", value: m }); }
  const setProfessionalTitle = (m) => { _setProfessionalTitle(m); storage.state({ key: "professionalTitle", value: m }); }
  const setDisableNext = (m) => { _setDisableNext(m); storage.state({ key: "disableNext", value: m }); }
  const setCompletedSteps = (m) => { _setCompletedSteps(m); storage.state({ key: "completedSteps", value: m }); }
  const setProcessUploadCalls = (m) => { _setProcessUploadCalls(m); storage.state({ key: "processUploadCalls", value: m }); }
  const setPlaceholdersUsed = (m) => { _setPlaceholdersUsed(m); storage.state({ key: "placeholdersUsed", value: m }); }
  const setParse = (m) => { _setParse(m); storage.state({ key: "parse", value: m }); }

  const resetState = () => {
    setActiveStep({});
    setSteps([]);
    setService(null);
    setData({});
    setLoading(false);
    setDisableNext(false);
    setProcessUploadCalls([]);
    setPlaceholdersUsed(false);

    // setOutputs(DEFAULT_OUTPUTS);
    // setOutputLength(DEFAULT_OUTPUT_LENGTH);
    // setActiveOutput("");
    // setActiveOutputIndex(DEFAULT_ACTIVE_OUTPUT_INDEX);
    // setUserResume("");
    // setUserLinkedInProfile("");
    // setUserInformation("");
    // setJobPositionPosting("");
    // setMessageRecipientInformation("");
    // setQuestions("");
    // setConnections("");
    // setFurtherContext("");
    // setCompany("");
    // setJobTitle("");
    // setUserName("");
    // setProfessionalTitle("");
    // setCompletedSteps(DEFAULT_COMPLETED_STEPS);
  };
  
  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <MantineProvider>
          <BrowserRouter>
            <Pipeline
              userKey={userKey}
              resetState={resetState}
              activeStepIndex={activeStepIndex}
              activeStep={activeStep}
              steps={steps}
              service={service}
              data={data}
              outputs={outputs}
              outputLength={outputLength}
              activeOutput={activeOutput}
              activeOutputIndex={activeOutputIndex}
              userResume={userResume}
              userLinkedInProfile={userLinkedInProfile}
              userInformation={userInformation}
              companyInformation={companyInformation}
              jobPositionPosting={jobPositionPosting}
              messageRecipientInformation={messageRecipientInformation}
              questions={questions}
              connections={connections}
              furtherContext={furtherContext}
              loading={loading}
              company={company}
              jobTitle={jobTitle}
              userName={userName}
              professionalTitle={professionalTitle}
              disableNext={disableNext}
              completedSteps={completedSteps}
              processUploadCalls={processUploadCalls}
              placeholdersUsed={placeholdersUsed}
              parse={parse}
              setActiveStepIndex={setActiveStepIndex}
              setActiveStep={setActiveStep}
              setSteps={setSteps}
              setService={setService}
              setData={setData}
              setOutputs={setOutputs}
              setOutputLength={setOutputLength}
              setActiveOutput={setActiveOutput}
              setActiveOutputIndex={setActiveOutputIndex}
              setUserResume={setUserResume}
              setUserLinkedInProfile={setUserLinkedInProfile}
              setUserInformation={setUserInformation}
              setCompanyInformation={setCompanyInformation}
              setJobPositionPosting={setJobPositionPosting}
              setMessageRecipientInformation={setMessageRecipientInformation}
              setQuestions={setQuestions}
              setConnections={setConnections}
              setFurtherContext={setFurtherContext}
              setLoading={setLoading}
              setCompany={setCompany}
              setJobTitle={setJobTitle}
              setUserName={setUserName}
              setProfessionalTitle={setProfessionalTitle}
              setDisableNext={setDisableNext}
              setCompletedSteps={setCompletedSteps}
              setProcessUploadCalls={setProcessUploadCalls}
              setPlaceholdersUsed={setPlaceholdersUsed}
              setParse={setParse}
            />
          </BrowserRouter>
        </MantineProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

import React, { useState, useId } from "react";
import styles from "./TextArea.module.scss";
import sanitize from "../../util/sanitize";
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from '@mui/material/InputBase';

function TextArea(props) {
	const initialValue = sanitize(props.initialValue || "", props.skipSanitize);
	const [value, setValue] = useState(initialValue);
	const id = useId();

	const handleChange = (e) => {
		const newValue = sanitize(e?.target?.value || "", props.skipSanitize);
		if (!props.usePropValue) {
			setValue(newValue);
		}
		if (props.onChange) {
			props.onChange(e);
		}
	}

	return (
		<div className={styles.textAreaWrapper}>
			<InputBase
				id={`textarea-${id}`}
				className={styles.textArea}
				sx={{
					border: "1px solid #E0E0E0",
					borderRadius: "0px",
					color: "#FFFFFF",
					padding: "5px 10px",
				}}
				label={props.label || props.placeholder || ""}
				placeholder={props.placeholder || props.label || ""}
				minRows={props.minRows || 4}
				onChange={(e) => handleChange(e)}
				value={
					props.usePropValue ?
					props?.value
					: 
					value
				}
				fullWidth
				multiline
				disabled={props.loading}
				{...props}
			/>
			{
				props.loading ?
				<div className={styles.loadingContainer}>
					<CircularProgress
						className={styles.loading}
						size={60}
					/>
				</div>
				:
					<></>
			}
		</div>
	)
}

export default TextArea;
import { v4 as uuidv4 } from 'uuid';
import { UUID_PERMIT } from '../constants';

const UUID_KEY = 'apply-samurai-uuid';

const useUUID = () => {
  if (UUID_PERMIT) {
    let userKey = localStorage.getItem(UUID_KEY);
    if (!userKey) {
      userKey = uuidv4();
      localStorage.setItem(UUID_KEY, userKey);
    }
    return userKey;
  } else {
    return 'disabled'
  }
};

export default useUUID;
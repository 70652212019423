import React from "react";
import styles from "./Error.module.scss";
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Logo from "../../assets/icons/apply-samurai-icon.png";
import {
	useNavigate,
} from "react-router-dom";

function Error(props) {
	const navigate = useNavigate();

	return (
		<div className={styles.error}>
			<h1 className={styles.errorTitle}>404</h1>
			<p className={styles.errorDescription}>We're Sorry, Something Went Wrong</p>
			<Button className={styles.button} variant="contained" onClick={() => navigate("/")}>Return Home</Button>
		</div>
	)
}

export default Error;
import React, { useRef, useState } from "react";
import styles from "./Upload.module.scss";
import { Group } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import TaskIcon from '@mui/icons-material/Task';
import Button from '@mui/material/Button';


function Upload(props) {
  const openRef = useRef(null);
	const [uploadedFile, setUploadedFile] = useState(props.uploadedFile || null);


  const onFileChange = (e) => {
    setUploadedFile(e);
		if (props.onFileChange) {
			props.onFileChange(e);
		}
  };

	return (
		<div className={styles.upload}>
			<Dropzone
        openRef={openRef}
        onDrop={onFileChange}
        className={styles.dropzone}
        radius="md"
        accept={[MIME_TYPES.pdf]}
        maxSize={30 * 1024 ** 2}
      >
				<Group justify="center">
					<Dropzone.Accept>
						<DownloadIcon />
					</Dropzone.Accept>
					<Dropzone.Reject>
						<CloseIcon />
					</Dropzone.Reject>
					<Dropzone.Idle>
						{
							uploadedFile === null ? 
								<CloudUploadIcon />
							:
								<TaskIcon />
						}
					</Dropzone.Idle>
				</Group>

				<div className={styles.feedback}>
					<Dropzone.Accept sx={{ color: '#FFFFFF' }}>Drop files here</Dropzone.Accept>
					<Dropzone.Reject sx={{ color: '#FFFFFF' }}>Pdf file less than 30mb</Dropzone.Reject>
					<Dropzone.Idle sx={{ color: '#FFFFFF' }}>{uploadedFile === null ? "Upload Resume (optional)" : "Resume Successfully Uploaded"}</Dropzone.Idle>
				</div>
				<Button className={styles.control} variant="contained" onClick={() => openRef.current?.()} sx={{ color: '#DDDDDD' }}>
					Browse files
				</Button>
      </Dropzone>
				<div className={styles.notes}>
					Drag and drop your resume here or click Browse Files to upload. We can accept only <i>.pdf</i> files that
					are less than 30mb in size.
				</div>

		</div>
	)
}

export default Upload;
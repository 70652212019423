import React from "react";
import {
	TextAreaStep,
	ServiceWrapper,
} from "../../components";


const RecipientInformation = ({ activeStep, data = null, onChange = null, ...props}) => (
	<ServiceWrapper {...props}>
		<TextAreaStep
			onChange={onChange}
			activeStep={activeStep}
			data={data}
			activeStepIndex={props.activeStepIndex}
			stepLen={props.stepLen}
		/>
	</ServiceWrapper>
)

export default RecipientInformation;
import React from "react";
import styles from "./ACard.module.scss";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


function ACard(props) {

	return (
		<a
			className={styles.aCard}
			href={props.href || "#"}
			rel={props.rel || ""}
			target={props.target || ""}
			onClick={(e) => props.onClick(e)}
		>
			<Card
				className={styles.card}
				sx={{
					height: "100%",
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					textAlign: "center",
					borderRadius: "0px",
				}}
			>
				<CardContent className={styles.cardContent}>
					<div className={styles.icon}>{props.icon}</div>
					<div className={styles.content}>{props.children}</div>
				</CardContent>
			</Card>
		</a>
	)
}

export default ACard;
import {
  parseIfJSON,
  stringifyIfJSON,
} from "../helpers/helpers";

const STATE_STORAGE_TYPE = "session";

const storage = {
  state: ({ key, value = null, defaultValue = null }) => {

    if (STATE_STORAGE_TYPE === "local") {
      if (value) {
        localStorage.setItem(key, stringifyIfJSON(value));
      } else {
        return parseIfJSON(localStorage.getItem(key)) || defaultValue;
      }
    } else if (STATE_STORAGE_TYPE === "session") {
      if (value) {
        sessionStorage.setItem(key, stringifyIfJSON(value));
      } else {
        return parseIfJSON(sessionStorage.getItem(key)) || defaultValue;
      }
    }
  },
  step: ({ key, value = null, defaultValue = null }) => {

    if (STATE_STORAGE_TYPE === "local") {
      let stepData = localStorage.getItem("stepData")
      if (stepData === null) {
        stepData = {};
        localStorage.setItem("stepData", JSON.stringify(stepData));
      } else {
        stepData = JSON.parse(stepData);
      }
      if (value) {
        stepData[key] = value;
        localStorage.setItem("stepData", JSON.stringify(stepData));
      } else {
        return stepData?.[key] || defaultValue;
      }
    } else if (STATE_STORAGE_TYPE === "session") {
      let stepData = sessionStorage.getItem("stepData")
      if (stepData === null) {
        stepData = {};
        sessionStorage.setItem("stepData", JSON.stringify(stepData));
      } else {
        stepData = JSON.parse(stepData);
      }
      if (value) {
        stepData[key] = value;
        sessionStorage.setItem("stepData", JSON.stringify(stepData));
      } else {
        return stepData?.[key] || defaultValue;
      }
    }
  },
  local: {
    set: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
    get: (key) => JSON.parse(localStorage.getItem(key)),
    remove: (key) => localStorage.removeItem(key),
    clear: () => localStorage.clear(),
  },
  session: {
    set: (key, value) => sessionStorage.setItem(key, JSON.stringify(value)),
    get: (key) => JSON.parse(sessionStorage.getItem(key)),
    remove: (key) => sessionStorage.removeItem(key),
    clear: () => sessionStorage.clear(),
  },
  cookie: {
    set: (key, value, days) => {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = key + "=" + value + expires + "; path=/";
    },
    get: (key) => {
      let name = key + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
      }
      return null;
    },
    remove: (key) => {
      document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    clear: () => {
      let cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
    },
  },
};

export default storage;
import React, { useState, useEffect } from "react";
import styles from "./ServiceWrapper.module.scss";
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import MobileStepper from '@mui/material/MobileStepper';
import TuneIcon from '@mui/icons-material/Tune';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import UploadIcon from '@mui/icons-material/Upload';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Check from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import {
	useLocation,
} from "react-router-dom";
import {
	BG2,
} from "../../constants";

function QTuneIcon(props) {
  const { completed } = props;

  return (
    <div>
			{completed ? (
				<Check />
			) : (
				<TuneIcon />
			)}
		</div>
  );
}

function QVideoLabelIcon(props) {
  const { completed } = props;

  return (
    <div>
			{completed ? (
				<Check />
			) : (
				<VideoLabelIcon />
			)}
		</div>
  );
}

function QUploadIcon(props) {
  const { completed } = props;

  return (
    <div>
			{completed ? (
				<Check />
			) : (
				<UploadIcon />
			)}
		</div>
  );
}

function QAutoAwesomeIcon(props) {
  const { completed } = props;

  return (
    <div>
			{completed ? (
				<Check />
			) : (
				<AutoAwesomeIcon />
			)}
		</div>
  );
}

const ICON_MAP = {
	"text": QVideoLabelIcon,
	"upload": QUploadIcon,
	"generate": QAutoAwesomeIcon,
}

const findServiceByHref = (href="", services={}) => {
	const hrefSplit = href.split("/");
	const serviceHref = hrefSplit[1];
	const stepHref = hrefSplit[2];
	let serviceKey = "";
	let stepIndex = 0;
	let steps = [];
	
	for (const i in Object.keys(services)) {
		const service = Object.entries(services)[i];
		if (service[1].href === `/${serviceHref}`) {
			serviceKey = service[0];
			steps = Object.values(service[1].steps);
			for (const j in Object.keys(service[1].steps)) {
				const currentStep = Object.entries(service[1].steps)[j];
				if (currentStep[1].href === stepHref) {
					stepIndex = j;
					break;
				}
			}
			break;
		}
	}
	return {
		serviceKey: serviceKey,
		stepIndex: stepIndex,
		steps: steps,
	};
};


function ServiceWrapper(props) {
	const [service, setService] = useState(props.service || findServiceByHref(props.href, props.services));
	const [activeStepIndex, setActiveStepIndex] = useState(props.activeStepIndex || 0);
	const [steps, setSteps] = useState(props.steps || []);
	const location = useLocation();

	useEffect(() => {
		const newService = findServiceByHref(location.pathname, props.services)
		if (newService.serviceKey !== service) {
			setService(newService);
		}
		if (newService.stepIndex !== activeStepIndex) {
			setActiveStepIndex(newService.stepIndex);
		}
		if (newService.steps !== steps) {
			setSteps(newService.steps);
		}
	}, [location]);

	return (
		<div className={styles.serviceWrapper}>
		{
				!props.isMobile ?
					<div className={styles.stepper}>
						<Stepper
							nonLinear
							activeStep={activeStepIndex}
							sx={{ color: '#FFFFFF' }}
						>
							{activeStepIndex !== -1 &&
								<Step
									sx={{ color: '#FFFFFF' }}
									completed={props?.completedSteps?.[props?.service]?.includes(activeStepIndex)}
								>
									<StepLabel alternativeLabel sx={{ color: '#FFFFFF' }} StepIconComponent={QTuneIcon}>
										<div style={{ color: '#FFFFFF' }}>
											{props.services?.[service]?.title}
										</div>
									</StepLabel>
								</Step>
							}
							{
								Object.values(steps).map((step, i) => (
									<Step
										key={`${step.label}-${i}`}
										optional={step.optional}
										sx={{ color: '#FFFFFF' }}
										completed={props?.completedSteps?.[props?.service]?.includes(activeStepIndex)}
									>
										<StepButton color="inherit" onClick={() => props.changeStep(i)}>
											<StepLabel
												alternativeLabel
												sx={{ color: '#FFFFFF' }}
												StepIconComponent={ICON_MAP[step.icon]}
												optional={step.optional ? <Typography variant="caption" sx={{ color: '#FFFFFF80' }}>Optional</Typography> : <></>}
											>
												<div style={{ color: '#FFFFFF' }}>
													{step.label}
												</div>
											</StepLabel>
										</StepButton>
									</Step>
								))
							}
						</Stepper>
					</div>
				:
					<div className={styles.mobileStepper}>
						{activeStepIndex !== -1 &&
							<MobileStepper
								variant="progress"
								steps={props.stepLen}
								position="static"
								activeStep={activeStepIndex}
								sx={{
									color: '#FFFFFF',
									backgroundColor: 'transparent'
								}}
								nextButton={
									props.stepKey !== "output" ?
										<LoadingButton
											variant="filled"
											loading={props.loading}
											loadingIndicator={<CircularProgress sx={{ color: BG2 }} size={40} />}
											endIcon={activeStepIndex === props.stepLen - 2 ? <></> : <ArrowForwardIcon />}
											onClick={() => props.nextStep()}
											disabled={props.disableNext}
										>
											{activeStepIndex === props.stepLen - 2 ? "Generate" : "Next"}
										</LoadingButton>
									:
										<Button
											variant="filled"
											onClick={() => props.changeStep(-1)}
										>
											Home
										</Button>
								}
								backButton={
									<Button
										variant="filled"
										disabled={props.loading}
										startIcon={<ArrowBackIcon />}
										onClick={() => props.previousStep()}
									>
										Back
									</Button>
								}
							/>
						}
					</div>
			}
			<div className={styles.steps}>
				<div className={styles.stepBody}>
					{props.children}
				</div>
			</div>

			{!props.isMobile &&
				<div className={styles.stepButtons}>
					<div className={styles.previous}>
						<Button
							variant="contained"
							disabled={props.loading}
							startIcon={<ArrowBackIcon />}
							onClick={() => props.previousStep()}
						>
							Back
						</Button>
					</div>
					<div className={styles.next}>
						{
							props.stepKey !== "output" ?
								<LoadingButton
									variant="contained"
									loading={props.loading}
									loadingIndicator={<CircularProgress sx={{ color: BG2 }} size={40} />}
									endIcon={activeStepIndex === props.stepLen - 2 ? <></> : <ArrowForwardIcon />}
									onClick={() => props.nextStep()}
									disabled={props.disableNext}
								>
									{activeStepIndex === props.stepLen - 2 ? "Generate" : "Next"}
								</LoadingButton>
							:
								<Button
									variant="contained"
									onClick={() => props.changeStep(-1)}
								>
									Home
								</Button>
						}
					</div>
				</div>
			}
		</div>
	)
}

export default ServiceWrapper;
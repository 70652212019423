import React from "react";
import styles from "./Pipeline.module.scss";
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from "../../assets/icons/apply-samurai-icon.png";
import axios from "axios";
import {
	Routes,
	Route,
	useNavigate,
} from "react-router-dom";
import {
	API_URL,
	SERVICES,
} from "../../constants";
import {
	ServiceSelect,
	Chatbot,
} from "../../components";
import Connections from "../Connections";
import FurtherContext from "../FurtherContext";
import Generate from "../Generate";
import JobPosting from "../JobPosting";
import LinkedInProfile from "../LinkedInProfile";
import RecipientInformation from "../RecipientInformation";
import ResumeUpload from "../ResumeUpload";
import UserBio from "../UserBio";
import CompanyInformation from "../CompanyInformation";
import Questions from "../Questions";
import Error from "../Error";
import Sensei from "../Sensei";


function Pipeline(props) {
  const isMobile = useMediaQuery('(max-width: 767px)');
	const navigate = useNavigate();

	const handleServiceSelect = (newService, path) => {
		const serviceSteps = SERVICES[newService].steps;
		const firstStep = Object.values(serviceSteps)[0];
		props.setService(newService);
		props.setSteps(serviceSteps);
		props.setActiveStep(firstStep);
		props.setDisableNext(!firstStep.optional);
		props.setActiveStepIndex(0);
		navigate(path);
	}

	const changeStep = (newStepIndex) => {
		props.setActiveStepIndex(newStepIndex);
		const newStep = Object.values(props.steps)[newStepIndex]
		if (newStepIndex === -1) {
			props.resetState();
			navigate("/");
		} else {
			if (newStep?.stepKey === "output") {
				handleGenerate();
			}
			props.setActiveStep(newStep);
			props.setDisableNext(!newStep?.optional && !props.completedSteps[props.service].includes(newStep?.stepKey) ? true : false);
			navigate(`${SERVICES?.[props.service]?.href}${newStep.href}`);
		}
	}

	const handleGenerate = async () => {
		props.setLoading(true);
		await Promise.all(props.processUploadCalls);
		axios.post(`${API_URL}/${SERVICES[props.service].endpoint}/lite`, props.data)
		.then((res) => {
			props.setLoading(false);
			handleNewOutput(res.data.output);
			props.setPlaceholdersUsed(res.data?.placeholdersUsed || false);
			props.setUserName(res?.data?.userName || "");
			props.setProfessionalTitle(res?.data?.professionalTitle || "");
		})
		.catch((error) => {
			props.setLoading(false);
			console.log(`${props.service} - handleGenerate - ERROR`, error)
		});
	}

	const processUpload = (key) => {
		const processData = key === "USER_RESUME" ? props.data?.[key]?.[0] || "" : props.data?.[key];
		if (processData) {
			props.setLoading(true);
			const formData = new FormData();
			formData.append(key, processData);
			Object.entries(props.data).forEach(([key, value]) => {
				formData.append(key, value);
			});
			axios.post(`${API_URL}/${key.toLowerCase()}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((res) => {
				props.setLoading(false);
				if (key === "JOB_POSITION_POSTING") {
					props.setCompany(res.data.company);
					props.setJobTitle(res.data.jobTitle);
				} else {
					handleUpdateData(key, res.data.processed);
				}
				changeStep(props.activeStepIndex + 1);
				handleCompletedSteps();
			})
			.catch((error) => {
				console.log(`${key} - processUpload - ERROR`, error);
				props.setLoading(false);
				changeStep(props.activeStepIndex + 1);
				handleCompletedSteps();
			});
		} else {
			if (key === "JOB_POSITION_POSTING") {
				props.setCompany("");
				props.setJobTitle("");
			} else {
				handleUpdateData(key, "");
			}
			changeStep(props.activeStepIndex + 1);
			handleCompletedSteps();
		}
	}

	const processUploadAsync = (key) => {
		const processData = key === "USER_RESUME" ? props.data?.[key]?.[0] || "" : props.data?.[key];
		if (processData) {
			props.setLoading(true);
			const formData = new FormData();
			formData.append(key, processData);
			Object.entries(props.data).forEach(([key, value]) => {
				formData.append(key, value);
			});
			const apiCall = axios.post(`${API_URL}/${key.toLowerCase()}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((res) => {
				if (key === "JOB_POSITION_POSTING") {
					props.setCompany(res.data.company);
					props.setJobTitle(res.data.jobTitle);
				}
				handleUpdateData(key, res.data.processed);
			})
			.catch((error) => {
				console.log(`${key} - processUploadAsync - ERROR`, error);
			})
			.finally(() => {
				props.setProcessUploadCalls(m => m.filter(p => p !== apiCall));
			});
			
			props.setProcessUploadCalls(m => [...m, apiCall]);
			props.setLoading(false);
		} else {
			handleUpdateData(key, "");
		}
	}

	const nextStep = () => {
		if (props.activeStep.stepKey === "USER_RESUME") {
			processUpload("USER_RESUME");
		} else if (props.activeStep.stepKey === "USER_LINKEDIN_PROFILE") {
			processUpload("USER_LINKEDIN_PROFILE");
		} else if (props.activeStep.stepKey === "JOB_POSITION_POSTING") {
			processUpload("JOB_POSITION_POSTING");
		} else {
			changeStep(props.activeStepIndex + 1);
			handleCompletedSteps();
		}
	}

	const nextStepAsync = () => {
		if (props.activeStep.stepKey === "USER_RESUME" || props.activeStep.stepKey === "USER_LINKEDIN_PROFILE" || props.activeStep.stepKey === "JOB_POSITION_POSTING") {
			if (props.parse[props.activeStep.stepKey]) {
				processUploadAsync(props.activeStep.stepKey);
			}
		}
		changeStep(props.activeStepIndex + 1);
		handleCompletedSteps();
	}

	const handleCompletedSteps = () => {
		props.setCompletedSteps(m => ({
			...m,
			[props.service]: props?.completedSteps?.[props.service] ? 
				[
					...props.completedSteps[props.service],
					props.activeStep.stepKey,
				]
			:
				[props.activeStep.stepKey],
		}));
	};

	const previousStep = () => {
		changeStep(props.activeStepIndex - 1);
	}

	const handleChange = (key, value) => {
		handleUpdateData(key, value);
	}

	const handleNewOutput = (value) => {
		const newIndex = Object.keys(props.outputs[props.service]).length + 1;
		const newOutputs = {
			...props.outputs,
			[props.service]: {
				...props.outputs[props.service],
				[newIndex]: value,
			},
		};
		props.setOutputs(newOutputs);
		props.setOutputLength(m => ({
			...m,
			[props.service]: newIndex,
		}));
		props.setActiveOutput(newOutputs[props.service][newIndex]);
		props.setActiveOutputIndex(m => ({
			...m,
			[props.service]: newIndex,
		}));
	};

	const handleOutputChange = (value) => {
		props.setOutputs(m => ({
			...m,
			[props.service]: {
				...m[props.service],
				[props.activeOutputIndex[props.service]]: value,
			},
		}));
		props.setActiveOutput(value);
	};

	const handleActiveOutputChange = (index) => {
		props.setActiveOutputIndex(m => ({
			...m,
			[props.service]: index,
		}));
		const newActiveOutput = props.outputs[props.service][index];
		console.log("newActiveOutput", index, newActiveOutput);
		props.setActiveOutput(newActiveOutput);
	};

	const handleUpdateData = (key, value) => {
		console.log('fired');
		if (key === "output") {
			handleOutputChange(value);
		} else {
			if (key === "USER_RESUME") {
				props.setUserResume(value);
			} else if (key === "USER_LINKEDIN_PROFILE") {
				props.setUserLinkedInProfile(value);
			} else if (key === "USER_INFORMATION") {
				props.setUserInformation(value);
			} else if (key === "COMPANY_INFORMATION") {
				props.setCompanyInformation(value);
			} else if (key === "JOB_POSITION_POSTING") {
				props.setJobPositionPosting(value);
			} else if (key === "MESSAGE_RECIPIENT_INFORMATION") {
				props.setMessageRecipientInformation(value);
			} else if (key === "QUESTIONS") {
				props.setQuestions(value);
			} else if (key === "CONNECTIONS") {
				props.setConnections(value);
			} else if (key === "FURTHER_CONTEXT") {
				props.setFurtherContext(value);
			}
			props.setParse(m => ({
				...m,
				[key]: true,
			}));
			props.setData(m => ({
				...m,
				[key]: value,
			}));
			if (!props.activeStep.optional && value !== "") {
				props.setDisableNext(false);
			}
		}
	}

	return (
		<div className={styles.pipeline}>
			{/* <Chatbot /> */}
			<AppBar position="static" sx={{ marginBottom: "15px" }}>
				<Toolbar>
					<div className={styles.logo} onClick={() => changeStep(-1)}>
						<img className={styles.logoImg} src={Logo} alt="logo" />
					</div>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						Apply SamurAI
					</Typography>
				</Toolbar>
			</AppBar>
			<div className={styles.body}>
				<Routes>
					<Route path="/" element={<ServiceSelect onClick={handleServiceSelect} services={SERVICES} navigate={navigate} />} />
					<Route path="/sensei" element={<Sensei />} />
					<Route path="/cover-letter">
						<Route path="resume" element={<ResumeUpload stepKey="USER_RESUME" href="/cover-letter/resume" parse={props.parse} setParse={props.setParse} onChange={handleChange} onFileChange={handleUpdateData} data={props.userResume} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/cover-letter/linkedin-profile" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userLinkedInProfile} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="job-posting" element={<JobPosting stepKey="JOB_POSITION_POSTING" href="/cover-letter/job-posting" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.jobPositionPosting} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/cover-letter/bio" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userInformation} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="connections" element={<Connections stepKey="CONNECTIONS" href="/cover-letter/connections" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.connections} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/cover-letter/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={handleOutputChange} regenerate={handleGenerate} outputs={props.outputs} outputLength={props.outputLength} activeOutput={props.activeOutput} setActiveOutput={handleActiveOutputChange} activeOutputIndex={props.activeOutputIndex} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="/follow-up">
						<Route path="resume" element={<ResumeUpload stepKey="USER_RESUME" href="/follow-up/resume" parse={props.parse} setParse={props.setParse} onChange={handleChange} onFileChange={handleUpdateData} data={props.userResume} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/follow-up/linkedin-profile" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userLinkedInProfile} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="job-posting" element={<JobPosting stepKey="JOB_POSITION_POSTING" href="/follow-up/job-posting" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.jobPositionPosting} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/follow-up/bio" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userInformation} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="recipient-info" element={<RecipientInformation stepKey="MESSAGE_RECIPIENT_INFORMATION" href="/follow-up/recipient-information" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.messageRecipientInformation} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="connections" element={<Connections stepKey="CONNECTIONS" href="/follow-up/connections" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.connections} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="additional-details" element={<FurtherContext stepKey="FURTHER_CONTEXT" href="/follow-up/additional-details" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.furtherContext} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/follow-up/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={handleOutputChange} regenerate={handleGenerate} outputs={props.outputs} outputLength={props.outputLength} activeOutput={props.activeOutput} setActiveOutput={handleActiveOutputChange} activeOutputIndex={props.activeOutputIndex} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="/reach-out">
						<Route path="resume" element={<ResumeUpload stepKey="USER_RESUME" href="/reach-out/resume" parse={props.parse} setParse={props.setParse} onChange={handleChange} onFileChange={handleUpdateData} data={props.userResume} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/reach-out/linkedin-profile" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userLinkedInProfile} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/reach-out/bio" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userInformation} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="company-info" element={<CompanyInformation stepKey="COMPANY_INFORMATION" href="/reach-out/company-info" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.companyInformation} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="recipient-info" element={<RecipientInformation stepKey="MESSAGE_RECIPIENT_INFORMATION" href="/reach-out/recipient-information" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.messageRecipientInformation} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="connections" element={<Connections stepKey="CONNECTIONS" href="/reach-out/connections" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.connections} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="additional-details" element={<FurtherContext stepKey="FURTHER_CONTEXT" href="/reach-out/additional-details" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.furtherContext} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/reach-out/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={handleOutputChange} regenerate={handleGenerate} outputs={props.outputs} outputLength={props.outputLength} activeOutput={props.activeOutput} setActiveOutput={handleActiveOutputChange} activeOutputIndex={props.activeOutputIndex} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="/questions">
						<Route path="resume" element={<ResumeUpload stepKey="USER_RESUME" href="/questions/resume" parse={props.parse} setParse={props.setParse} onChange={handleChange} onFileChange={handleUpdateData} data={props.userResume} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/questions/linkedin-profile" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userLinkedInProfile} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="job-posting" element={<JobPosting stepKey="JOB_POSITION_POSTING" href="/questions/job-posting" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.jobPositionPosting} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/questions/bio" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userInformation} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="question" element={<Questions stepKey="QUESTIONS" href="/questions/question" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.questions} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="additional-details" element={<FurtherContext stepKey="FURTHER_CONTEXT" href="/questions/additional-details" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.furtherContext} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/questions/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={handleOutputChange} regenerate={handleGenerate} outputs={props.outputs} outputLength={props.outputLength} activeOutput={props.activeOutput} setActiveOutput={handleActiveOutputChange} activeOutputIndex={props.activeOutputIndex} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="/build-resume">
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/build-resume/linkedin-profile" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userLinkedInProfile} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/build-resume/bio" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userInformation} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/build-resume/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={handleOutputChange} regenerate={handleGenerate} outputs={props.outputs} outputLength={props.outputLength} activeOutput={props.activeOutput} setActiveOutput={handleActiveOutputChange} activeOutputIndex={props.activeOutputIndex} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="/improve-resume">
						<Route path="resume" element={<ResumeUpload stepKey="USER_RESUME" href="/improve-resume/resume" parse={props.parse} setParse={props.setParse} onChange={handleChange} onFileChange={handleUpdateData} data={props.userResume} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/improve-resume/linkedin-profile" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userLinkedInProfile} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/improve-resume/bio" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userInformation} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/improve-resume/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={handleOutputChange} regenerate={handleGenerate} outputs={props.outputs} outputLength={props.outputLength} activeOutput={props.activeOutput} setActiveOutput={handleActiveOutputChange} activeOutputIndex={props.activeOutputIndex} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="/tune-resume">
						<Route path="resume" element={<ResumeUpload stepKey="USER_RESUME" href="/tune-resume/resume" parse={props.parse} setParse={props.setParse} onChange={handleChange} onFileChange={handleUpdateData} data={props.userResume} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/tune-resume/linkedin-profile" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userLinkedInProfile} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="job-posting" element={<JobPosting stepKey="JOB_POSITION_POSTING" href="/tune-resume/job-posting" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.jobPositionPosting} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/tune-resume/bio" parse={props.parse} setParse={props.setParse} onChange={handleChange} data={props.userInformation} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/tune-resume/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={handleOutputChange} regenerate={handleGenerate} outputs={props.outputs} outputLength={props.outputLength} activeOutput={props.activeOutput} setActiveOutput={handleActiveOutputChange} activeOutputIndex={props.activeOutputIndex} completedSteps={props.completedSteps} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="*" element={<Error />} />
				</Routes>
			</div>
		</div>
	)
}

export default Pipeline;
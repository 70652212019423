import React from "react";
import styles from "./ResumeUpload.module.scss";
import Typography from '@mui/material/Typography';
import {
	Upload,
	ServiceWrapper,
} from "../../components";


function ResumeUpload({ data = null, onChange = null, ...props}) {
	const handleFileChange = (file) => {
		if (onChange) {
			onChange('USER_RESUME', file);
		}
	}

	return (
		<ServiceWrapper {...props}>
			<div className={styles.resumeUpload}>
				<div className={styles.text}>
					<h2 className={styles.title}>{props.activeStep.title}</h2>
					{
						props.activeStep.optional &&
						<div className={styles.caption}>
							<p className={styles.optional}>This step is optional{props.activeStep.recommended ? ", but highly recommended" : ""}.</p>
							<p className={styles.optional}>If you do not wish to complete this step, simply press the NEXT button.</p>
						</div>
					}
				</div>
				<div className={styles.input}>
					<Upload
						pipeline
						onFileChange={handleFileChange}
						uploadedFile={data}
					/>
				</div>
				<Typography className={styles.helperText} variant="caption">{props.activeStep.helperText}</Typography>
			</div>
		</ServiceWrapper>
	)
}

export default ResumeUpload;
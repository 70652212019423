import React from "react";
import styles from "./ServiceSelect.module.scss";
import {
	ACard
} from "../../components";
import SamuraiIcon from "../../assets/icons/samurai.png";


function ServiceSelect(props) {

	const handleOnClick = (e, service, path) => {
		e.preventDefault();
		if (props.onClick) {
			props.onClick(service, path);
		}
	}

	return (
		<div className={styles.serviceSelect}>
			<div className={styles.hero}>
				<h1 className={styles.welcome}>Welcome to Apply SamurAI</h1>
				<p className={styles.description}>I am a simple and powerful AI aid that can generate several things for you to help with your job applications.</p>
			</div>
			<div className={styles.content}>
				<h2 className={styles.contentTitle}>What Would You Like Me To Help You With?</h2>
				<div className={styles.services}>
					{
						Object.entries(props.services).map((service) => (
							<ACard
								key={service[1].name}
								icon={service[1].icon}
								onClick={(e) => handleOnClick(e, service[0], `${service[1].href}/${Object.values(service[1].steps)[0].label.toLowerCase().replace(/\s/g, "-")}`)}
								href={`${service[1].href}/${Object.values(service[1].steps)[0].label.toLowerCase().replace(/\s/g, "-")}`}
								target={props.target}
								rel={props.rel}
							>
								{service[1].title}
							</ACard>
							)
						)
					}
					<ACard
						key="sensei"
						icon={
							<img
								src={SamuraiIcon}
								alt=""
								style={{
									height: "48px",
									width: "48px",
								}}
							/>
						}
						onClick={() => props.navigate("/sensei")}
						href="/sensei"
						target={props.target}
						rel={props.rel}
					>
						Sensei
					</ACard>
				</div>
			</div>
		</div>
	)
}

export default ServiceSelect;


export const isVowel = (char) => {
  return ['a', 'i', 'u', 'e', 'o'].includes(char);
};

export const parseIfJSON = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export const stringifyIfJSON = (obj) => {
  try {
    return JSON.stringify(obj);
  } catch (e) {
    return obj;
  }
}
import DOMPurify from 'dompurify';


const sanitize = (html, skip=false) => {
  if (skip) {
    // return html;
    return DOMPurify.sanitize(html, {
      ALLOWED_TAGS: [
        'b', 'i', 'em', 'strong', 'a', 'p', 'ul', 'ol', 'li', 'h1', 'h2', 'h3', 'h4', 'h5',
        'h6', 'blockquote', 'pre', 'br', 'hr', 'div', 'caption', 'html', 'body', 'head', 'style'
      ],
      ALLOWED_ATTR: ['href', 'target', 'class'],
      FORBID_TAGS: ['script', 'iframe', 'object', 'embed', 'applet', 'base', 'meta', 'title'],
    });
  } else {
    // return DOMPurify.sanitize(html, {
    //   ALLOWED_TAGS: ['head', 'style', 'html', 'body'],
    //   FORBID_TAGS: ['script', 'iframe', 'object', 'embed', 'applet', 'base', 'meta', 'title'],
    //   // ALLOWED_ATTR: {}, // Allow no attributes on <style> or other tags
    //   WHOLE_DOCUMENT: true // Allow sanitizing the entire document structure
    // });
    return DOMPurify.sanitize(html);
  }
}

export default sanitize;
import React, { useState, useId } from "react";
import styles from "./Input.module.scss";
import sanitize from "../../util/sanitize";
import NumberInput from "../NumberInput/NumberInput";
import TextField from '@mui/material/TextField';


function Input(props) {
	const initialValue = sanitize(props.initialValue || "", props.skipSanitize);
	const [value, setValue] = useState(initialValue);
	const id = useId();

	const handleChange = (e) => {
		const newValue = sanitize(e?.target?.value || "", props.skipSanitize);
		setValue(newValue);
		if (props.onChange) {
			props.onChange(newValue);
		}
	}

	return (
		<>
			{
				props.default ?
					<>
						{
							props.type === 'number' ?
									<NumberInput {...props} />
								:
									<div className={styles.inputContainer}>
										{
											props.label ?
													<label
														className={styles.label}
														for={`input-${id}`}
													>
														{props.label}
													</label>
												:
													<></>
										}
										<input
											id={`input-${id}`}
											className={styles.input}
											style={props.style || {}}
											name={`input-${id}`}
											value={props.value || value}
											placeholder={props.placeholder || ""}
											type={props.type || "text"}
											onChange={(e) => handleChange(e)}
										/>
									</div>
						}
					</>
				:
				<>
					{
						props.type === 'number' ?
								<NumberInput default {...props} />
							:
								<TextField
									id={`input-${id}`}
									className={styles.input}
									sx={props.style || {}}
									label={props.label || props.placeholder || ""}
									placeholder={props.placeholder || props.label || ""}
									type={props.type || "text"}
									value={props.value || value}
									onChange={(e) => handleChange(e)}
								/>
					}
				</>
			}
		</>
	)
}

export default Input;
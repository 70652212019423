import React from "react";
import styles from "./TextAreaStep.module.scss";
import Typography from '@mui/material/Typography';
import {
	TextArea,
} from "../../components";


function TextAreaStep(props) {
	const handleChange = (e) => {
		if (props.onChange) {
			props.onChange(props.activeStep.stepKey, e.target.value);
		}
	}

	return (
		<div className={styles.textAreaStep}>
			<div className={styles.text}>
				<h2 className={styles.title}>{props.activeStep.title}</h2>
				{
					props.activeStep.optional ?
						<div className={styles.caption}>
							<p className={styles.optional}>This step is optional{props.activeStep.recommended ? ", but highly recommended" : ""}.</p>
							<p className={styles.optional}>
								If you do not wish to complete this step, simply press the {props.stepLen - 2 !== props.activeStepIndex ? "NEXT" : "GENERATE"} button.
							</p>
						</div>
					:
						<></>
				}
			</div>
			<div className={styles.input}>
				<TextArea
					onChange={(e) => handleChange(e)}
					minRows={15}
					maxRows={15}
					rows={15}
					value={props?.data || ""}
					style={{
						xs: { width: "100%" },
						sm: { width: "100%" },
						md: { width: "50%" },
					}}
				/>
			</div>
			<Typography className={styles.helperText} variant="caption">{props.activeStep.helperText}</Typography>
		</div>
	)
}

export default TextAreaStep;
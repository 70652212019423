import React from "react";
import styles from "./ButtonCard.module.scss";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


function ButtonCard(props) {

	return (
		<button className={styles.buttonCard} onClick={props.onClick}>
			<Card
				sx={{
					height: "100%",
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CardContent>
					{props.children}
				</CardContent>
			</Card>
		</button>
	)
}

export default ButtonCard;